exports.onRouteUpdate = async () => {
    // Max attempts calculation: 5 seconds / 500ms
    const maxAttempts = 10;
    let attempts = 0;

    // Function to delay execution
    const delay = (duration) => new Promise((resolve) => setTimeout(resolve, duration));

    // Function to check condition and execute code
    const attemptExecution = async () => {
        // Increment attempt count
        attempts += 1;

        // Check conditions are perfect
        if (window._storagePopulated === true && typeof window.adaEmbed === "object") {
            const sixSenseData = JSON.parse(window.localStorage._6senseCompanyDetails);

            if (sixSenseData.company.company_match !== "Match") {
                // console.log("No match from 6Sense.");
                return;
            }

            const segmentIds = sixSenseData.segments.ids.map((id) => `[${id}]`).join(" ");

            let adaData = {
                "6s_country": sixSenseData.company.country,
                "6s_employee_range": sixSenseData.company.employee_range,
                "6s_industry": sixSenseData.company.industry,
                "6s_name": sixSenseData.company.name,
                "6s_region": sixSenseData.company.region,
                "6s_revenue_range": sixSenseData.company.revenue_range,
            };

            if (segmentIds !== "") {
                adaData["6s_segments"] = segmentIds;
            }

            window.adaEmbed.setMetaFields(adaData);
            // console.log("sending the adaData object", adaData);
        } else if (attempts < maxAttempts) {
            // If condition not met and max attempts not reached, try again after 500ms
            await delay(500);
            await attemptExecution();
        } else {
            // Max attempts reached, do nothing
            // console.log("Max attempts reached, exiting.");
        }
    };

    await attemptExecution();
};
