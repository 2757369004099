/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import scrollWithHeaderOffset from "@v4/utils/scrollWithHeaderOffset";
import gaRouteUpdate from "@v4/utils/gaRouteUpdate";
import "./src/styles/global.css";

export const onRouteUpdate = ({ location }) => {
    // Send Pageviews to GA
    gaRouteUpdate(window, location);
};

// Fires when scroll position should be updated (including jump-links), and on any Gatsby-Link based navigation.
// Does not fire on page-refresh unless a scrollPosition is set.
export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
    // Get saved position - this may be [0, 0]
    const [savedX, savedY] = getSavedScrollPosition(routerProps.location);

    // This is only set on "filter" pages. To make it not jump around.
    const { disableScrollUpdate, xPos, yPos } = routerProps?.location?.state ?? false;
    if (disableScrollUpdate) {
        // If the saved Y position is not 0 or not equal to the one provided in the route state. Use the saved values.
        // Otherwise use the values provided in the route state
        if (savedY !== 0 && savedY !== yPos) {
            requestAnimationFrame(() => {
                window.scrollTo(savedX, savedY);
            });
        } else {
            requestAnimationFrame(() => {
                window.scrollTo(xPos, yPos);
            });
        }

        return false;
    }

    // Jump-links:
    // If the matching element exists, scroll to it.
    if (routerProps.location.hash && document.querySelector(routerProps.location.hash)) {
        // Tell the browser to update the scroll position manually
        window.history.scrollRestoration = "manual";

        // Scroll to the matching element - Note: Duration 0 defaults to 1s of animation.
        scrollWithHeaderOffset(routerProps.location.hash, 1);

        // Don't update scroll position to saved X/Y
        return false;
    }

    // Scroll to saved position.
    window.scrollTo(savedX, savedY);

    // In rare case that the saved position was lower than the initial page length, try again.
    if (window.scrollX !== savedX || window.scrollY !== savedY) {
        requestAnimationFrame(() => {
            window.scrollTo(savedX, savedY);
        });
    }

    return false;
};
