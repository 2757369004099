/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import { I18nProvider } from "@v4/utils/i18nContext";

export const wrapPageElement = ({ element, props }) => {
    if (props.pageContext.translatedPaths) {
        return (
            <I18nProvider translatedPaths={props.pageContext.translatedPaths} locale={props.pageContext.locale}>
                {element}
            </I18nProvider>
        );
    } else {
        // it's a 404 (which has it's own provider instance). So return the element
        return element;
    }
};
