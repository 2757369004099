import React, { createContext, useState, useContext, useEffect } from "react";
import { createI18nHelper } from "@v4/utils/i18nHelper";

const I18nContext = createContext("");

const I18nProvider = ({ children, locale, translatedPaths }) => {
    const [i18nHelper, setI18nHelper] = useState(createI18nHelper(locale));

    // Every time url changes we update our context helper
    useEffect(() => {
        const currentHelper = createI18nHelper(locale);
        setI18nHelper(currentHelper);
    }, [locale]);

    return <I18nContext.Provider value={{ i18n: i18nHelper, translatedPaths }}>{children}</I18nContext.Provider>;
};

const useI18n = () => {
    const context = useContext(I18nContext);

    if (!context) {
        throw new Error("useI18n must be used within an I18nProvider");
    }

    return context;
};
export { I18nProvider, useI18n };
