exports.components = {
  "component---src-pages-builtin-news-index-js": () => import("./../../../src/pages/builtin_news/index.js" /* webpackChunkName: "component---src-pages-builtin-news-index-js" */),
  "component---src-pages-builtin-news-oss-js": () => import("./../../../src/pages/builtin_news/oss.js" /* webpackChunkName: "component---src-pages-builtin-news-oss-js" */),
  "component---src-pages-http-status-map-js": () => import("./../../../src/pages/http-status-map.js" /* webpackChunkName: "component---src-pages-http-status-map-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-templates-404-404-js": () => import("./../../../src/templates/404/404.js" /* webpackChunkName: "component---src-templates-404-404-js" */),
  "component---src-templates-blog-author-page-blog-author-page-js": () => import("./../../../src/templates/blogAuthorPage/blogAuthorPage.js" /* webpackChunkName: "component---src-templates-blog-author-page-blog-author-page-js" */),
  "component---src-templates-blog-category-page-blog-category-page-js": () => import("./../../../src/templates/blogCategoryPage/blogCategoryPage.js" /* webpackChunkName: "component---src-templates-blog-category-page-blog-category-page-js" */),
  "component---src-templates-blog-landing-page-blog-landing-page-js": () => import("./../../../src/templates/blogLandingPage/blogLandingPage.js" /* webpackChunkName: "component---src-templates-blog-landing-page-blog-landing-page-js" */),
  "component---src-templates-blog-single-entry-page-blog-single-entry-page-js": () => import("./../../../src/templates/blogSingleEntryPage/blogSingleEntryPage.js" /* webpackChunkName: "component---src-templates-blog-single-entry-page-blog-single-entry-page-js" */),
  "component---src-templates-customer-customer-js": () => import("./../../../src/templates/customer/customer.js" /* webpackChunkName: "component---src-templates-customer-customer-js" */),
  "component---src-templates-news-entry-listing-page-news-entry-listing-page-js": () => import("./../../../src/templates/newsEntryListingPage/newsEntryListingPage.js" /* webpackChunkName: "component---src-templates-news-entry-listing-page-news-entry-listing-page-js" */),
  "component---src-templates-news-room-page-news-room-page-js": () => import("./../../../src/templates/newsRoomPage/newsRoomPage.js" /* webpackChunkName: "component---src-templates-news-room-page-news-room-page-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-press-release-page-press-release-page-js": () => import("./../../../src/templates/pressReleasePage/pressReleasePage.js" /* webpackChunkName: "component---src-templates-press-release-page-press-release-page-js" */),
  "component---src-templates-resource-page-resource-page-js": () => import("./../../../src/templates/resourcePage/resourcePage.js" /* webpackChunkName: "component---src-templates-resource-page-resource-page-js" */),
  "component---src-templates-seo-category-hub-page-seo-category-hub-page-js": () => import("./../../../src/templates/SEOCategoryHubPage/SEOCategoryHubPage.js" /* webpackChunkName: "component---src-templates-seo-category-hub-page-seo-category-hub-page-js" */),
  "component---src-templates-seo-page-seo-page-js": () => import("./../../../src/templates/seoPage/seoPage.js" /* webpackChunkName: "component---src-templates-seo-page-seo-page-js" */)
}

