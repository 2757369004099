import scrollToElement from "scroll-to-element";

export default (to, duration = 500, additionalOffset = 0) => {
    const notificationBar = document.querySelector(".notificationBar");
    const notificationBarHeight = notificationBar?.offsetHeight;
    const notificationBarVisible = notificationBar
        ? window.getComputedStyle(notificationBar).getPropertyValue("opacity")
        : false;

    const headerHeight = document.querySelector(".siteHeader")?.offsetHeight;
    const totalHeight = notificationBarVisible ? notificationBarHeight + headerHeight : headerHeight;

    scrollToElement(to, { offset: -(totalHeight + 10) + additionalOffset, ease: "in-out-cube", duration });
};
