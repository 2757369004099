module.exports = [{
      plugin: require('../../../libs/gatsby-theme-talend/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Talend","short_name":"Talend","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"992b94f926a6f6bad9247a6840e76ae7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PSBN","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../libs/gatsby-theme-talend/src/plugins/gatsby-plugin-marketo/gatsby-browser.js'),
      options: {"plugins":[],"munchkinId":"049-DKK-796","additionalProps":{"altIds":["347-IAT-677"]}},
    },{
      plugin: require('../plugins/gatsby-plugin-onetrust-modal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../libs/gatsby-theme-talend/src/plugins/gatsby-plugin-6sense-ada/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
